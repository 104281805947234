<template>
  <b-row>
    <b-col sm="12" md="8">
      <my-card title="Sortir" collapsed :badge="$store.getters['operationalins/isFiltered'] ? 'Filtered':''">
        <template slot="body">
          <form @submit.prevent="submitFilter">
            <b-row>
              <b-col sm="12">
                <form-select-new :filter-id="idBranchArray" url="v1/branch_select2" :queryparams="{id_regional: mainRegionalId}" v-model="filter.branch" label="Cabang"></form-select-new>
              </b-col>
              <b-col sm="6">
                <form-date :max="maxDate" v-model="filter.date_1" label="Tanggal Mulai"></form-date>
              </b-col>
              <b-col sm="6">
                <form-date :min="minDate" v-model="filter.date_2" label="Tanggal Selesai"></form-date>
              </b-col>
              <b-col sm="12">
                <form-select-static :options="utils.statuses" v-model="filter.id_status" label="Status"></form-select-static>
              </b-col>
              <b-col sm="12">
                <button-action size="sm" @clicked="submitFilter" name="Filter" featherIcon="FilterIcon"></button-action>
                <button-action size="sm" @clicked="resetFilter" name="Reset" variant="warning"></button-action>
                <button-action v-if="cP(137)" size="sm" @clicked="exportExcel" variant="warning" name="Export"></button-action>
              </b-col>
            </b-row>
          </form>
        </template>
      </my-card>
    </b-col>
    <b-col sm="12">
      <my-card title="Daftar Operasional Masuk">
        <template slot="button">
          <button-add v-if="cP(113)" :to="{name: 'operationalins-add'}" variant="primary"></button-add>
        </template>
        <template slot="body">
          <data-tables ref="datatables" endpoint="v1/operational_ins" :query-params="filterQuery" :fields="fields">
            <template v-slot:2="{item}">
              {{item[2] | mindate}}
            </template>
            <template v-slot:4="{item}">
              {{item[4] | formatNumber}}
            </template>
            <template v-slot:5="{item}">
              {{item[5] | formatNumber}}
            </template>
            <template v-slot:6="data">
              <div class="d-flex justify-content-end">
                <button-link v-if="(isPusat||isRegional)&&cP(115)" size="sm" :to="{name:'operationalins-approve',params:{id: data.item[6]}}" feather-icon="CheckIcon"></button-link>
                <button-link v-if="cP(116) && data.item[7]==1" size="sm" :to="{name:'operationalins-edit',params:{id: data.item[6]}}" feather-icon="EditIcon"></button-link>
                <button-delete v-if="cP(117) && data.item[7]==1" @ondelete="deleteRow" :id="data.item[6]" :name="`${data.item[0]} (${data.item[1]})`"></button-delete>
              </div>
            </template>
          </data-tables>
        </template>
      </my-card>
    </b-col>
  </b-row>
</template>

<script>
import ButtonAdd from '@/my-components/ButtonAdd.vue'
import ButtonAction from '@/my-components/ButtonAction.vue'
import DataTables from '@/my-components/DataTables.vue'
import ButtonDelete from '@/my-components/ButtonDelete.vue'
import ButtonLink from '@/my-components/ButtonLink.vue'
import FormSelectNew from '@/my-components/FormSelectNew'
import FormSelectStatic from '@/my-components/FormSelectStatic'
import FormDate from '@/my-components/FormDate'
import { mapFields } from 'vuex-map-fields'
import utils from './utils'
import FileSaver from 'file-saver'

export default {
  components:{
    ButtonAdd,
    DataTables,
    ButtonDelete,
    ButtonLink,
    FormSelectNew,
    FormDate,
    ButtonAction,
    FormSelectStatic
  },
  data() {
    return {
      utils:utils,
      fields: {
        0:{sortable: true, searchable: true, label:'Kode'},
        1:{sortable: true, searchable: true, label:'Cabang'},
        2:{sortable: true, searchable: true, label:'Tanggal'},
        3:{sortable: true, searchable: true, label:'Status'},
        4:{sortable: true, searchable: true, label:'Total'},
        5:{sortable: true, searchable: true, label:'Total Persetujuan'},
        6:{sortable: false, searchable: false, label:''},
      }
    }
  },
  computed:{
    ...mapFields('operationalins',['filter']),
    userProfile(){
      return this.$store.state.auth.profile
    },
    minDate(){
      const min = this.filter.date_1
      if(!min) return null
      else return this.$moment(min).format()
    },
    maxDate(){
      const max = this.filter.date_2
      if(!max) return null
      else return this.$moment(max).format()
    },
    filterQuery(){
      let filters = JSON.parse(JSON.stringify(this.filter))
      if(filters.branch) filters.id_branch = filters.branch.value
      delete filters.branch
      return filters
    },

  },
  methods:{
    firstInit(){
      if(!this.isPusat) {
        if(!this.filter.branch)
        this.filter.branch = {value:this.userProfile.id_branch,label:this.userProfile.branch_name}
      }
    },
    submitFilter(){
      this.$refs.datatables.refresh()
    },
    resetFilter(){
      if(this.isPusat){
        this.filter.id_branch = ""
        this.filter.branch = null
      }
      this.filter.date_1 = ""
      this.filter.date_2 = ""
      this.filter.id_status = ""
      this.$refs.datatables.refresh()
    },
    async deleteRow(payload){
      try {
        await this.$store.dispatch('operationalins/destroy',payload)
        this.$refs.datatables.refresh()
        this.toastSuccess("OK","Data Berhasil Dihapus!")
      } catch (error) {
        this.handleError(error)
      }
    },
    async exportExcel(){
      const filters = this.filter
      try {
        const res = await this.$http.get(`v1/operational_in_export`,{params:filters,responseType: 'blob'})
        FileSaver.saveAs(res.data,'OPERASIONAL MASUK')
      } catch (error) {
        this.handleError(error)
      }
    }
  },
  created(){
    this.firstInit()
  }
}
</script>

<style>

</style>