const statuses = [
  {value:"",label:"Semua Status"},
  {value:"1",label:"Draft"},
  {value:"2",label:"Disetujui Pusat"},
]

const defaultReductions = {
  "id_expense" : null,
  "expense_name" : "",
  "note": "",
  "amount": 0
}

export default {
  statuses,
  defaultReductions
}